/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/


 import 'core-js/es7/array';   // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

 (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

 /*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
(window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */
import 'hammerjs';
import 'custom-event-polyfill/custom-event-polyfill';
import 'intl';
import 'intl/locale-data/jsonp/en';
import 'ie9-oninput-polyfill';
import 'angular-polyfills/dist/blob';
import 'angular-polyfills/dist/classlist';
import 'angular-polyfills/dist/formdata';
import 'angular-polyfills/dist/intl';
import 'angular-polyfills/dist/typedarray';
import 'console-polyfill';


const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const ieVersion = ua.substring(msie + 5, ua.indexOf('.', msie));
// console.log(ieVersion);
if (ieVersion === '9') {
// import 'angular-polyfills/dist/shim';
  import ('angular-polyfills/dist/shim').then(() => {
    // console.log("pollyfill ie ==========");
    // console.log(ieVersion);
  });

}

